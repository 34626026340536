import { ResponseError } from "api/errors";
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from 'react-query';
import { useFetchData } from 'hooks/use-fetch-data';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * Leverages the internal Python implmeentation of UUID (uuid.UUID) to provide native UUID objects
   * in fields, resolvers and input.
   */
  UUID: any;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any;
  /**
   * The `GenericScalar` scalar type represents a generic
   * GraphQL scalar value that could be:
   * String, Boolean, Int, Float, List or Object.
   */
  GenericScalar: any;
};

export type Query = {
  __typename?: 'Query';
  /** The ID of the object */
  node?: Maybe<Node>;
  /** Server version */
  version?: Maybe<Scalars['String']>;
  profile?: Maybe<User>;
  aws?: Maybe<Aws>;
  gcp?: Maybe<Gcp>;
  diagnostics?: Maybe<Diagnostics>;
  dashboard?: Maybe<Dashboard>;
  process?: Maybe<CloudProcessing>;
};


export type QueryNodeArgs = {
  id: Scalars['ID'];
};

/** An object with an ID */
export type Node = {
  /** The ID of the object. */
  id: Scalars['ID'];
};

export type User = Node & {
  __typename?: 'User';
  /** The ID of the object. */
  id: Scalars['ID'];
  companyId?: Maybe<Scalars['Int']>;
  uuid?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  status?: Maybe<UserStatus>;
  company?: Maybe<Company>;
};


/** An enumeration. */
export enum UserStatus {
  Pending = 'PENDING',
  Verified = 'VERIFIED',
  Disabled = 'DISABLED'
}

export type Company = Node & {
  __typename?: 'Company';
  /** The ID of the object. */
  id: Scalars['ID'];
  uuid?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  setupStage?: Maybe<SetupStage>;
  projectKey: Scalars['String'];
  users?: Maybe<UserConnection>;
  cloudAccounts?: Maybe<CloudAccountConnection>;
};


export type CompanyUsersArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type CompanyCloudAccountsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** An enumeration. */
export enum SetupStage {
  Initial = 'INITIAL',
  Script = 'SCRIPT',
  Role = 'ROLE',
  Region = 'REGION',
  Processing = 'PROCESSING',
  Results = 'RESULTS',
  Complete = 'COMPLETE'
}

export type UserConnection = {
  __typename?: 'UserConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserEdge>>;
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
};

/** A Relay edge containing a `User` and its cursor. */
export type UserEdge = {
  __typename?: 'UserEdge';
  /** The item at the end of the edge */
  node?: Maybe<User>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CloudAccountConnection = {
  __typename?: 'CloudAccountConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CloudAccountEdge>>;
};

/** A Relay edge containing a `CloudAccount` and its cursor. */
export type CloudAccountEdge = {
  __typename?: 'CloudAccountEdge';
  /** The item at the end of the edge */
  node?: Maybe<CloudAccount>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CloudAccount = Node & {
  __typename?: 'CloudAccount';
  /** The ID of the object. */
  id: Scalars['ID'];
  companyId?: Maybe<Scalars['Int']>;
  cloud?: Maybe<Cloud>;
  account?: Maybe<Scalars['String']>;
  roleName?: Maybe<Scalars['String']>;
  arn?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  regions?: Maybe<CloudRegionsConnection>;
  processes?: Maybe<CloudProcessingConnection>;
};


export type CloudAccountRegionsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type CloudAccountProcessesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** An enumeration. */
export enum Cloud {
  Aws = 'AWS',
  Gcp = 'GCP',
  Azure = 'AZURE',
  Dc = 'DC'
}

export type CloudRegionsConnection = {
  __typename?: 'CloudRegionsConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CloudRegionsEdge>>;
};

/** A Relay edge containing a `CloudRegions` and its cursor. */
export type CloudRegionsEdge = {
  __typename?: 'CloudRegionsEdge';
  /** The item at the end of the edge */
  node?: Maybe<CloudRegions>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CloudRegions = Node & {
  __typename?: 'CloudRegions';
  /** The ID of the object. */
  id: Scalars['ID'];
  cloudId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  cloudAccount?: Maybe<CloudAccount>;
};

export type CloudProcessingConnection = {
  __typename?: 'CloudProcessingConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CloudProcessingEdge>>;
};

/** A Relay edge containing a `CloudProcessing` and its cursor. */
export type CloudProcessingEdge = {
  __typename?: 'CloudProcessingEdge';
  /** The item at the end of the edge */
  node?: Maybe<CloudProcessing>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CloudProcessing = Node & {
  __typename?: 'CloudProcessing';
  /** The ID of the object. */
  id: Scalars['ID'];
  cloudId?: Maybe<Scalars['Int']>;
  status?: Maybe<CloudProcessingStatus>;
  start?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
  analyzed?: Maybe<Scalars['Float']>;
  cloudAccount?: Maybe<CloudAccount>;
};

/** An enumeration. */
export enum CloudProcessingStatus {
  Pending = 'PENDING',
  Scheduled = 'SCHEDULED',
  Processing = 'PROCESSING',
  Done = 'DONE',
  Error = 'ERROR'
}


export type Aws = {
  __typename?: 'AWS';
  services?: Maybe<Array<Maybe<AwsService>>>;
  regions?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AwsService = {
  __typename?: 'AWSService';
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
};

export type Gcp = {
  __typename?: 'GCP';
  regions?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Diagnostics = {
  __typename?: 'Diagnostics';
  services?: Maybe<Array<Maybe<OpportunityEvent>>>;
  top?: Maybe<Array<Maybe<OpportunityObj>>>;
  opportunitiesCount?: Maybe<Scalars['Int']>;
  annualCost?: Maybe<Scalars['Int']>;
};


export type DiagnosticsTopArgs = {
  count?: Maybe<Scalars['Int']>;
};

export type OpportunityEvent = {
  __typename?: 'OpportunityEvent';
  awsService?: Maybe<AwsService>;
  resource?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  savings?: Maybe<Scalars['Float']>;
};

export type OpportunityObj = {
  __typename?: 'OpportunityObj';
  checkName?: Maybe<Scalars['String']>;
  detailType?: Maybe<Scalars['String']>;
  account?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['DateTime']>;
  resourceArn?: Maybe<Scalars['String']>;
  serviceType?: Maybe<Scalars['String']>;
  resourceType?: Maybe<Scalars['String']>;
  currentCost?: Maybe<Scalars['Float']>;
  savings?: Maybe<Scalars['Float']>;
  workflowType?: Maybe<Scalars['String']>;
  awsService?: Maybe<AwsService>;
  /** Status is an enum transformed into string */
  status?: Maybe<Scalars['String']>;
  /** Combines jiraSOUTicket, jiraEXTicket, jiraCRTicket */
  jiraTicket?: Maybe<Scalars['String']>;
  playbook?: Maybe<PlaybookObj>;
  resourceId?: Maybe<Scalars['String']>;
};

export type PlaybookObj = Node & {
  __typename?: 'PlaybookObj';
  checkName: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  /** Title specific for rendering on diagnostics ui */
  titleDiagnostics?: Maybe<Scalars['String']>;
  bucket?: Maybe<Scalars['Int']>;
  optimizationCat?: Maybe<Scalars['String']>;
  savingsTypeCategory?: Maybe<Scalars['String']>;
  serviceType?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  awsService?: Maybe<AwsService>;
  details?: Maybe<PlaybookDetailsObj>;
};

export type PlaybookDetailsObj = Node & {
  __typename?: 'PlaybookDetailsObj';
  checkName: Scalars['String'];
  resourceType?: Maybe<Scalars['String']>;
  decision?: Maybe<Scalars['String']>;
  rationale?: Maybe<Scalars['String']>;
  /** User friendly description based on rationale for diagnostics page */
  description?: Maybe<Scalars['String']>;
  algorithm?: Maybe<Scalars['String']>;
  impact?: Maybe<Scalars['String']>;
  probability?: Maybe<Scalars['String']>;
  remediation?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
};

export type Dashboard = {
  __typename?: 'Dashboard';
  playbook?: Maybe<PlaybookDetailsObj>;
  playbookList?: Maybe<Array<Maybe<PlaybookDetailsObj>>>;
};


export type DashboardPlaybookArgs = {
  checkName?: Maybe<Scalars['String']>;
};

export type Mutations = {
  __typename?: 'Mutations';
  /** Signin(email:"foo@bar.com", password:"Abc1234") */
  Signin?: Maybe<SignIn>;
  Signout?: Maybe<SignOut>;
  /** Signup(email:"foo@bar.com", name:"Foo", password:"Abc1234", company:"Foobar") */
  Signup?: Maybe<SignUp>;
  /** VerifyCode(email:"foo@bar.com", code:"123456") { */
  VerifyCode?: Maybe<VerifyUser>;
  /** VerifyResend(email:"foo@bar.com") */
  VerifyResend?: Maybe<VerifyResend>;
  ForgotPassword?: Maybe<ForgotPassword>;
  ChangePassword?: Maybe<ChangePassword>;
  SetupScript?: Maybe<SetupScript>;
  SetupRole?: Maybe<SetupRole>;
  SetupRegions?: Maybe<SetupRegions>;
  SetupProcessing?: Maybe<SetupProcessing>;
  SetupProcessInfo?: Maybe<SetupProcessInfo>;
  SendEmail?: Maybe<SendEmail>;
};


export type MutationsSigninArgs = {
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};


export type MutationsSignoutArgs = {
  token?: Maybe<Scalars['String']>;
};


export type MutationsSignupArgs = {
  company?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};


export type MutationsVerifyCodeArgs = {
  code?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};


export type MutationsVerifyResendArgs = {
  email?: Maybe<Scalars['String']>;
};


export type MutationsForgotPasswordArgs = {
  email?: Maybe<Scalars['String']>;
};


export type MutationsChangePasswordArgs = {
  code?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  oldPassword?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};


export type MutationsSetupScriptArgs = {
  cloud?: Maybe<Cloud>;
  company?: Maybe<Scalars['UUID']>;
  region?: Maybe<Scalars['String']>;
};


export type MutationsSetupRoleArgs = {
  arn?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['UUID']>;
  credentials?: Maybe<Scalars['GenericScalar']>;
};


export type MutationsSetupRegionsArgs = {
  company?: Maybe<Scalars['UUID']>;
  regions?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationsSetupProcessingArgs = {
  analyzed?: Maybe<Scalars['Float']>;
  company?: Maybe<Scalars['UUID']>;
};


export type MutationsSetupProcessInfoArgs = {
  analyzed?: Maybe<Scalars['Float']>;
  company?: Maybe<Scalars['UUID']>;
};


export type MutationsSendEmailArgs = {
  message: Message;
};

/** Signin(email:"foo@bar.com", password:"Abc1234") */
export type SignIn = {
  __typename?: 'SignIn';
  token?: Maybe<Scalars['String']>;
  me?: Maybe<User>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type SignOut = {
  __typename?: 'SignOut';
  ok?: Maybe<Scalars['Boolean']>;
};

/** Signup(email:"foo@bar.com", name:"Foo", password:"Abc1234", company:"Foobar") */
export type SignUp = {
  __typename?: 'SignUp';
  me?: Maybe<User>;
  ok?: Maybe<Scalars['Boolean']>;
};

/** VerifyCode(email:"foo@bar.com", code:"123456") { */
export type VerifyUser = {
  __typename?: 'VerifyUser';
  ok?: Maybe<Scalars['Boolean']>;
};

/** VerifyResend(email:"foo@bar.com") */
export type VerifyResend = {
  __typename?: 'VerifyResend';
  ok?: Maybe<Scalars['Boolean']>;
};

export type ForgotPassword = {
  __typename?: 'ForgotPassword';
  ok?: Maybe<Scalars['Boolean']>;
};

export type ChangePassword = {
  __typename?: 'ChangePassword';
  ok?: Maybe<Scalars['Boolean']>;
};

export type SetupScript = {
  __typename?: 'SetupScript';
  ok?: Maybe<Scalars['Boolean']>;
  company?: Maybe<Company>;
};

export type SetupRole = {
  __typename?: 'SetupRole';
  ok?: Maybe<Scalars['Boolean']>;
  company?: Maybe<Company>;
};


export type SetupRegions = {
  __typename?: 'SetupRegions';
  ok?: Maybe<Scalars['Boolean']>;
  company?: Maybe<Company>;
};

export type SetupProcessing = {
  __typename?: 'SetupProcessing';
  ok?: Maybe<Scalars['Boolean']>;
  company?: Maybe<Company>;
};

export type SetupProcessInfo = {
  __typename?: 'SetupProcessInfo';
  ok?: Maybe<Scalars['Boolean']>;
  company?: Maybe<Company>;
};

export type SendEmail = {
  __typename?: 'SendEmail';
  ok?: Maybe<Scalars['Boolean']>;
};

export type Message = {
  toEmail: EmailTo;
  subject: Subject;
  phoneNumber?: Maybe<Scalars['String']>;
  body: Scalars['String'];
};

export enum EmailTo {
  Support = 'support',
  Sales = 'sales'
}

export enum Subject {
  Information = 'information',
  TechSupport = 'tech_support',
  SignUp = 'sign_up'
}

export type CompanyFFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'uuid' | 'name' | 'setupStage'>
);

export type UserFFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'name' | 'email' | 'status'>
  & { company?: Maybe<(
    { __typename?: 'Company' }
    & CompanyFFragment
  )> }
);

export type ChangePasswordMutationVariables = Exact<{
  code?: Maybe<Scalars['String']>;
  oldPassword?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type ChangePasswordMutation = (
  { __typename?: 'Mutations' }
  & { ChangePassword?: Maybe<(
    { __typename?: 'ChangePassword' }
    & Pick<ChangePassword, 'ok'>
  )> }
);

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotPasswordMutation = (
  { __typename?: 'Mutations' }
  & { ForgotPassword?: Maybe<(
    { __typename?: 'ForgotPassword' }
    & Pick<ForgotPassword, 'ok'>
  )> }
);

export type SetupRegionsMutationVariables = Exact<{
  company: Scalars['UUID'];
  regions: Array<Scalars['String']> | Scalars['String'];
}>;


export type SetupRegionsMutation = (
  { __typename?: 'Mutations' }
  & { SetupRegions?: Maybe<(
    { __typename?: 'SetupRegions' }
    & Pick<SetupRegions, 'ok'>
  )> }
);

export type SetupRoleMutationVariables = Exact<{
  arn: Scalars['String'];
}>;


export type SetupRoleMutation = (
  { __typename?: 'Mutations' }
  & { SetupRole?: Maybe<(
    { __typename?: 'SetupRole' }
    & Pick<SetupRole, 'ok'>
  )> }
);

export type SetupScriptMutationVariables = Exact<{
  region?: Maybe<Scalars['String']>;
}>;


export type SetupScriptMutation = (
  { __typename?: 'Mutations' }
  & { SetupScript?: Maybe<(
    { __typename?: 'SetupScript' }
    & Pick<SetupScript, 'ok'>
  )> }
);

export type SigninMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type SigninMutation = (
  { __typename?: 'Mutations' }
  & { Signin?: Maybe<(
    { __typename?: 'SignIn' }
    & Pick<SignIn, 'token'>
    & { me?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )> }
  )> }
);

export type SignoutMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type SignoutMutation = (
  { __typename?: 'Mutations' }
  & { Signout?: Maybe<(
    { __typename?: 'SignOut' }
    & Pick<SignOut, 'ok'>
  )> }
);

export type SignupMutationVariables = Exact<{
  company: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
}>;


export type SignupMutation = (
  { __typename?: 'Mutations' }
  & { Signup?: Maybe<(
    { __typename?: 'SignUp' }
    & { me?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'email'>
    )> }
  )> }
);

export type VerifyCodeMutationVariables = Exact<{
  email: Scalars['String'];
  code: Scalars['String'];
}>;


export type VerifyCodeMutation = (
  { __typename?: 'Mutations' }
  & { VerifyCode?: Maybe<(
    { __typename?: 'VerifyUser' }
    & Pick<VerifyUser, 'ok'>
  )> }
);

export type VerifyResendMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type VerifyResendMutation = (
  { __typename?: 'Mutations' }
  & { VerifyResend?: Maybe<(
    { __typename?: 'VerifyResend' }
    & Pick<VerifyResend, 'ok'>
  )> }
);

export type AwsRegionsQueryVariables = Exact<{ [key: string]: never; }>;


export type AwsRegionsQuery = (
  { __typename?: 'Query' }
  & { aws?: Maybe<(
    { __typename?: 'AWS' }
    & Pick<Aws, 'regions'>
  )> }
);

export type GetProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProfileQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & UserFFragment
  )> }
);

export type SendEmailMutationVariables = Exact<{
  message: Message;
}>;


export type SendEmailMutation = (
  { __typename?: 'Mutations' }
  & { SendEmail?: Maybe<(
    { __typename?: 'SendEmail' }
    & Pick<SendEmail, 'ok'>
  )> }
);

export type DiagnosticDashboardQueryVariables = Exact<{ [key: string]: never; }>;


export type DiagnosticDashboardQuery = (
  { __typename?: 'Query' }
  & { diagnostics?: Maybe<(
    { __typename?: 'Diagnostics' }
    & Pick<Diagnostics, 'annualCost' | 'opportunitiesCount'>
    & { services?: Maybe<Array<Maybe<(
      { __typename?: 'OpportunityEvent' }
      & Pick<OpportunityEvent, 'cost' | 'savings'>
      & { awsService?: Maybe<(
        { __typename?: 'AWSService' }
        & Pick<AwsService, 'shortName'>
      )> }
    )>>>, top?: Maybe<Array<Maybe<(
      { __typename?: 'OpportunityObj' }
      & Pick<OpportunityObj, 'region' | 'savings' | 'resourceId'>
      & { playbook?: Maybe<(
        { __typename?: 'PlaybookObj' }
        & Pick<PlaybookObj, 'titleDiagnostics'>
        & { details?: Maybe<(
          { __typename?: 'PlaybookDetailsObj' }
          & Pick<PlaybookDetailsObj, 'description'>
        )> }
      )> }
    )>>> }
  )>, process?: Maybe<(
    { __typename?: 'CloudProcessing' }
    & Pick<CloudProcessing, 'start' | 'end' | 'analyzed'>
  )> }
);

export const CompanyFFragmentDoc = `
    fragment CompanyF on Company {
  id
  uuid
  name
  setupStage
}
    `;
export const UserFFragmentDoc = `
    fragment UserF on User {
  id
  name
  email
  status
  company {
    ...CompanyF
  }
}
    ${CompanyFFragmentDoc}`;
export const ChangePasswordDocument = `
    mutation ChangePassword($code: String, $oldPassword: String, $email: String!, $password: String!) {
  ChangePassword(
    code: $code
    oldPassword: $oldPassword
    email: $email
    password: $password
  ) {
    ok
  }
}
    `;
export const useChangePasswordMutation = <
      TError = ResponseError,
      TContext = unknown
    >(options?: UseMutationOptions<ChangePasswordMutation, TError, ChangePasswordMutationVariables, TContext>) => 
    useMutation<ChangePasswordMutation, TError, ChangePasswordMutationVariables, TContext>(
      useFetchData<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument),
      options
    );
export const ForgotPasswordDocument = `
    mutation ForgotPassword($email: String!) {
  ForgotPassword(email: $email) {
    ok
  }
}
    `;
export const useForgotPasswordMutation = <
      TError = ResponseError,
      TContext = unknown
    >(options?: UseMutationOptions<ForgotPasswordMutation, TError, ForgotPasswordMutationVariables, TContext>) => 
    useMutation<ForgotPasswordMutation, TError, ForgotPasswordMutationVariables, TContext>(
      useFetchData<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument),
      options
    );
export const SetupRegionsDocument = `
    mutation SetupRegions($company: UUID!, $regions: [String!]!) {
  SetupRegions(company: $company, regions: $regions) {
    ok
  }
}
    `;
export const useSetupRegionsMutation = <
      TError = ResponseError,
      TContext = unknown
    >(options?: UseMutationOptions<SetupRegionsMutation, TError, SetupRegionsMutationVariables, TContext>) => 
    useMutation<SetupRegionsMutation, TError, SetupRegionsMutationVariables, TContext>(
      useFetchData<SetupRegionsMutation, SetupRegionsMutationVariables>(SetupRegionsDocument),
      options
    );
export const SetupRoleDocument = `
    mutation SetupRole($arn: String!) {
  SetupRole(arn: $arn) {
    ok
  }
}
    `;
export const useSetupRoleMutation = <
      TError = ResponseError,
      TContext = unknown
    >(options?: UseMutationOptions<SetupRoleMutation, TError, SetupRoleMutationVariables, TContext>) => 
    useMutation<SetupRoleMutation, TError, SetupRoleMutationVariables, TContext>(
      useFetchData<SetupRoleMutation, SetupRoleMutationVariables>(SetupRoleDocument),
      options
    );
export const SetupScriptDocument = `
    mutation SetupScript($region: String) {
  SetupScript(region: $region) {
    ok
  }
}
    `;
export const useSetupScriptMutation = <
      TError = ResponseError,
      TContext = unknown
    >(options?: UseMutationOptions<SetupScriptMutation, TError, SetupScriptMutationVariables, TContext>) => 
    useMutation<SetupScriptMutation, TError, SetupScriptMutationVariables, TContext>(
      useFetchData<SetupScriptMutation, SetupScriptMutationVariables>(SetupScriptDocument),
      options
    );
export const SigninDocument = `
    mutation Signin($email: String!, $password: String!) {
  Signin(email: $email, password: $password) {
    token
    me {
      id
    }
  }
}
    `;
export const useSigninMutation = <
      TError = ResponseError,
      TContext = unknown
    >(options?: UseMutationOptions<SigninMutation, TError, SigninMutationVariables, TContext>) => 
    useMutation<SigninMutation, TError, SigninMutationVariables, TContext>(
      useFetchData<SigninMutation, SigninMutationVariables>(SigninDocument),
      options
    );
export const SignoutDocument = `
    mutation Signout($token: String!) {
  Signout(token: $token) {
    ok
  }
}
    `;
export const useSignoutMutation = <
      TError = ResponseError,
      TContext = unknown
    >(options?: UseMutationOptions<SignoutMutation, TError, SignoutMutationVariables, TContext>) => 
    useMutation<SignoutMutation, TError, SignoutMutationVariables, TContext>(
      useFetchData<SignoutMutation, SignoutMutationVariables>(SignoutDocument),
      options
    );
export const SignupDocument = `
    mutation Signup($company: String!, $email: String!, $name: String!, $password: String!) {
  Signup(company: $company, email: $email, name: $name, password: $password) {
    me {
      email
    }
  }
}
    `;
export const useSignupMutation = <
      TError = ResponseError,
      TContext = unknown
    >(options?: UseMutationOptions<SignupMutation, TError, SignupMutationVariables, TContext>) => 
    useMutation<SignupMutation, TError, SignupMutationVariables, TContext>(
      useFetchData<SignupMutation, SignupMutationVariables>(SignupDocument),
      options
    );
export const VerifyCodeDocument = `
    mutation VerifyCode($email: String!, $code: String!) {
  VerifyCode(email: $email, code: $code) {
    ok
  }
}
    `;
export const useVerifyCodeMutation = <
      TError = ResponseError,
      TContext = unknown
    >(options?: UseMutationOptions<VerifyCodeMutation, TError, VerifyCodeMutationVariables, TContext>) => 
    useMutation<VerifyCodeMutation, TError, VerifyCodeMutationVariables, TContext>(
      useFetchData<VerifyCodeMutation, VerifyCodeMutationVariables>(VerifyCodeDocument),
      options
    );
export const VerifyResendDocument = `
    mutation VerifyResend($email: String!) {
  VerifyResend(email: $email) {
    ok
  }
}
    `;
export const useVerifyResendMutation = <
      TError = ResponseError,
      TContext = unknown
    >(options?: UseMutationOptions<VerifyResendMutation, TError, VerifyResendMutationVariables, TContext>) => 
    useMutation<VerifyResendMutation, TError, VerifyResendMutationVariables, TContext>(
      useFetchData<VerifyResendMutation, VerifyResendMutationVariables>(VerifyResendDocument),
      options
    );
export const AwsRegionsDocument = `
    query AWSRegions {
  aws {
    regions
  }
}
    `;
export const useAwsRegionsQuery = <
      TData = AwsRegionsQuery,
      TError = ResponseError
    >(
      variables?: AwsRegionsQueryVariables, 
      options?: UseQueryOptions<AwsRegionsQuery, TError, TData>
    ) => 
    useQuery<AwsRegionsQuery, TError, TData>(
      ['AWSRegions', variables],
      useFetchData<AwsRegionsQuery, AwsRegionsQueryVariables>(AwsRegionsDocument).bind(null, variables),
      options
    );
export const GetProfileDocument = `
    query GetProfile {
  me: profile {
    ...UserF
  }
}
    ${UserFFragmentDoc}`;
export const useGetProfileQuery = <
      TData = GetProfileQuery,
      TError = ResponseError
    >(
      variables?: GetProfileQueryVariables, 
      options?: UseQueryOptions<GetProfileQuery, TError, TData>
    ) => 
    useQuery<GetProfileQuery, TError, TData>(
      ['GetProfile', variables],
      useFetchData<GetProfileQuery, GetProfileQueryVariables>(GetProfileDocument).bind(null, variables),
      options
    );
export const SendEmailDocument = `
    mutation SendEmail($message: Message!) {
  SendEmail(message: $message) {
    ok
  }
}
    `;
export const useSendEmailMutation = <
      TError = ResponseError,
      TContext = unknown
    >(options?: UseMutationOptions<SendEmailMutation, TError, SendEmailMutationVariables, TContext>) => 
    useMutation<SendEmailMutation, TError, SendEmailMutationVariables, TContext>(
      useFetchData<SendEmailMutation, SendEmailMutationVariables>(SendEmailDocument),
      options
    );
export const DiagnosticDashboardDocument = `
    query DiagnosticDashboard {
  diagnostics {
    annualCost
    opportunitiesCount
    services {
      cost
      savings
      awsService {
        shortName
      }
    }
    top {
      region
      savings
      resourceId
      playbook {
        titleDiagnostics
        details {
          description
        }
      }
    }
  }
  process {
    start
    end
    analyzed
  }
}
    `;
export const useDiagnosticDashboardQuery = <
      TData = DiagnosticDashboardQuery,
      TError = ResponseError
    >(
      variables?: DiagnosticDashboardQueryVariables, 
      options?: UseQueryOptions<DiagnosticDashboardQuery, TError, TData>
    ) => 
    useQuery<DiagnosticDashboardQuery, TError, TData>(
      ['DiagnosticDashboard', variables],
      useFetchData<DiagnosticDashboardQuery, DiagnosticDashboardQueryVariables>(DiagnosticDashboardDocument).bind(null, variables),
      options
    );